@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply w-full h-full;
}

#app {
  @apply flex flex-col w-full h-full;
}
